[data-slick-carousel-default] {
  margin-bottom: @grid-gutter-width;
  // &.slick-slider {
  //   margin-bottom: 0;
  // }

  .slide-image:not(.slick-slide):not(:first-child) {
    display: none;
  }

  .slick-slide {
    &, &:focus {
      outline: none;
    }
  }

  .slick-dots {
    bottom: 10px;
  }
  .slick-prev {
    left: 10px;
    z-index: 1;
  }
  .slick-next {
    right: 10px;
  }

  .slick-slide {
    img {
      margin: 0 auto;
    }
  }
}
