@import "../vendor/bootstrap/less/bootstrap";
@import "../vendor/font-awesome/less/font-awesome";
@import "fonts";
@import "variables";
/*
* Vendor specific
*/
@import "vendors/parsley";
@import "vendors/slick";

html, body {
  height: 100%;
}
:focus{
  outline:none;
}

#container {
  min-height: 100%;
  margin-bottom: -44px;
  padding-bottom: 44px;
}

#sidebar {
  padding-top: 1 * @font-size-h1;
}



.banner{
  position: relative;
  height: auto;
  padding-top:50%;
  overflow: hidden;

  @media(min-width: @screen-sm){
    // height: auto;
    // height: calc(~'100vh - 160px');

  }

  @media(min-width: @screen-md){
    padding-bottom:0;
    // height: auto;
    // height: calc(~'100vh - 160px');

  }

  .banner-image {
    img{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      
        @media(min-width: @screen-sm){
            object-fit: cover;
            object-position: top right; // change depending on left or right image
        // height: calc(~'100vh - 180px');
      
      }
      @media(min-width: @screen-lg){
      }
    }
  }
  
  .outer {
    position:absolute;
    height:~'calc(100% - 160px)';
    // height: ~'calc(100vh - 160px)';
    top:0;
    width:100%;
  }
  .table {
    display:table;
    height:100%;
    width:40%;
  }

  .table-cell {
    display:table-cell;
    vertical-align:middle;
  }

  .banner-body{
    padding:0 20px;
    // position:absolute;
    z-index:1000;
    color: @text-color;
    // top:50%;
    font-weight: 300;
    // top:10px;
    // left:50px;

      @media(min-width: 375px){
        // top:42%;
      }

      @media(min-width: @screen-sm){
        // top:40%;
      }

      @media(min-width: @screen-md){
        color: @white;
        // width: 35%;
        // top:50%;
      }

      header{
        // width: 50%;
        text-transform: uppercase;

          @media(min-width: @screen-sm){
            // margin-bottom: 120px;
          }
          @media(min-width: @screen-md){
            // width: 100%;
            // margin-bottom: 0;
          }
      }
  }

  .banner-title{
    font-size: 20px;
    font-weight: bold;
    color: @white;
    text-shadow: 1px 1px 5px #000;
    line-height: 1;
    border: 4px solid @white;
    padding: 10px;
    display:inline-block;
    // max-width:80%;
    margin-bottom: 10px;  
    // margin-top: 5px; 
    // float: left;
    // width: 145px;

      // @media (min-width: 375px) {
      //   font-size: 23px;
      //   padding: 15px;
      //   width: 170px;
      // }

      @media (min-width: @screen-sm) {
          font-size: 35px;
          margin-bottom:35px;
      //     line-height: 0.8;
      //     // margin-bottom: 0;
      //     // margin-top: 24px;
      //     // width: 240px;
      }
  }

  .subtitle{
    color:@white;
    font-size: 15px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    margin-top: 10px;
    clear:both;
    // float: left;
    position: relative;
    font-weight: bold;
      &:after{
        content:"";
        position: absolute;
        border-bottom: 3px solid #fff;
        bottom:0;
        left: 0;
        width:90px;
      }
      @media(min-width: 375px){
        font-size: 18px;
        margin-top: 15px;
      } 
      @media(min-width: @screen-sm){
        font-size: 25px;
        margin-bottom: 20px;
        margin-top:24px;
      }
  }

  .summary{
    clear:both;
  }

}


.banner-link {
    a{
      position:static;
      text-transform: uppercase;
      box-shadow: rgba(255, 255, 255, 1.0) 0 0px 0px 3px inset;
      border-radius: 50px 50px 50px 50px; 
      // width: 100%;
      display: block;
      padding: 18px 30px;
      text-align: center;
      font-size: 20px;
      // float: left;
      color: @white;
      margin: 24px 0;
      -webkit-transition: all 300ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
      -moz-transition: all 300ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
      -ms-transition: all 300ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
      -o-transition: all 300ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
      transition: all 300ms cubic-bezier(0.390, 0.500, 0.150, 1.360);
        // @media (min-width: @screen-xs) {
        //     font-size: 15px;
        //     margin-top: 10px;
        //     padding: 11px 15px;
        // }

                    max-width:245px;

        @media (min-width: @screen-md) {
            // font-size: 20px;
            // margin-top: 24px;
        }
        .fa{
          margin-left: 30px;
        }  
        &:hover, &.active, &:focus {
          box-shadow: rgba(255, 255, 255, 1) 0 0px 0px 40px inset;
          text-decoration: none;
        }
    }
    @media(min-width: @screen-sm){
        // width: 250px;
        // margin: auto;
    }
    @media(min-width: @screen-md){
      // width: 100%;
      // margin: auto;
    }  
  }

.theme(@text-color, @reverseColour: #fff, @buttonHoverColour: @text-color){

  .banner-title{
    border-color: @reverseColour;
    color:@reverseColour;
    text-shadow: none;
  }

  .banner-body {
    color:@reverseColour;
  }
   
  .banner-link a{
    box-shadow: @reverseColour 0 0 0 3px inset;
    color: @reverseColour;
    &:hover{
      box-shadow: @reverseColour 0 0 0 40px inset;
      color: @buttonHoverColour;
    }
}


  .page-header h2{
    color:@text-color;
    font-weight: bold;
  }
  .blockquote{
    border-left: 5px solid @text-color;
  }
  ul {
      list-style: none;
      padding: 0 0 12px;
      margin: 0;
      clear: both;
        li {
          padding-left: 1em; 
          text-indent: -.7em;
            &:before {
              content: "•  ";
              color: @text-color;
              font-size: 20px;
            }
        }
    }
    a{
      color:@text-color;
    }
    
    .banner-link{ 
      a:hover {
        color:@buttonHoverColour;
      }
    }
    .media-profile{
      line-height: 1.1;
      .media-heading{
        color: @text-color;
      }
    }
  .matrix-heading{ 
      .box{
      border: 5px solid @text-color;
      color: @text-color;
      }
      h1, h2, h3, h4, h5, h6{
        color: @text-color;
        font-weight: bold;
      }  
  }
}
.homepage{
  // .theme(@white);
  .banner-link {
    a:hover, a:focus{
      color: @brand-primary;
    }
  }
}
.about-us{
  .right-orientation;
  .theme(#49463d, #49463d, #fff);
  
}

// PANELS THAT HAVE CONTENT ON RIGHT
// DIFFERENT TO DEFAULT ORIENTATION
.right-orientation {

  small.attribution{
    left:10px;;
  }


   .banner .banner-image img {
     object-position: top right;
  }

  .outer {
        left:50% !important;

  }
  .banner .banner-body{
    // left:50% !important;
    // left:auto;
    width:auto;
    padding-right:20px;
  }
}

.no-title {
  .banner-title{display:none !important};
}


.chairmans-report{
  .right-orientation;
  .theme(#d3461b, #fff);
}

.chief-executives-review-sello-hatang{
  .theme(#25435d);
}



.dialogues{
  .theme(#024c57);
}

.memory{
  .right-orientation;
  .theme(#026b48);
}


.exhibitions{
  .theme(#d53a3a);

   .exhibition-panel{
    clear: both;
  }

}

.outreach{
  .right-orientation;
  .theme(#eb5900, #fff, #eb5900);
  .no-title;

}

.fw-bold {
  font-weight:bold;
}

.communication{
  .theme(#000, #000, #fff);
}


.staff {
  .right-orientation;
  .media-profile {
    .media-heading{
      font-size: 16px;
    }
    .media-summary{
      font-size:11px;
      color:#4a4a4a;
    }
  }
}

.trustees {
   .theme(#bb4911);
}

.governance-and-support {
    .theme(#bb4911);

}

.staff {
    .theme(#bb4911);
}


button.close{
    opacity: 1;
    text-shadow:none;
    span{
      color: #fff;
      width: 30px;
      height: 30px;
      position: absolute;
      top: -15px;
      right: -15px;
      z-index: 9;
      background: #bb4911;
      border-radius: 100%;
      padding: 5px;
      font-size: 20px;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.8);
    }
}

.annual-financial-statements{
  .right-orientation;
  .theme(#167482);
}


.donors{
  .right-orientation;
  .theme(#321259);
}

.web-analytics-report{
  .theme(#1d5880);
}

.nelson-mandela-centenary{
  .right-orientation;
  .theme(#49463d, #49463d);

   .page-header {
        display:none;
      }

    @media(min-width: @screen-sm){
      .page {
        display:none;
      }

    }

 

  .banner .banner-body {
    left:55% !important;
  }
}

.or-tambo-centenary{
  .theme(#fff, #49463d);
  .right-orientation;

//  #page {
//     display:none;
//   }
  
}


// .nelson-mandela-100{
//   .theme(#192e45);
//   .banner .banner-body{
//     left: 25%;
//     .banner-title{
//       width: auto;
//       max-width: 295px;
//     }
//     .banner-link a{
//       max-width: 390px;
//     }
//   }
// }

.mt-1 {
  margin-top: @line-height-computed * 1px;
}

.mt-2 {
  margin-top: @line-height-computed * 2px;
}

.mt-3 {
  margin-top: @line-height-computed * 3px;
}

.mb-1 {
  margin-bottom: @line-height-computed * 1;
}

.mb-2 {
  margin-bottom: @line-height-computed * 2;
}

.mb-3 {
  margin-bottom: @line-height-computed * 3;
}

.align-right {
  float: right;
}

.align-left {
  float: left;
}

.align-right, .align-left {
  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}

.clear-both {
  clear: both;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  position: relative;
  top: 50%;
  .translate(0%, -50%);
}

.horizontal-align-center {
  .translate(-50%, -50%);
  left: 50%;
  position: absolute;
  z-index: 99;
}

.caption {
  font-size: @font-size-small;
  min-height: @font-size-small;
  box-sizing: content-box;

  p {
    margin-bottom: 0;
  }

  .article-image-attribution {
    float: right;
  }
}



.jumbotron {
  *:last-child {
    margin-bottom: 0;
  }
}

.page-title {
  text-align: center;
  margin-top: @font-size-h1;
  margin-bottom: @font-size-h1;

  &.page-title-left {
    text-align: left;
  }
}

.page-header {
  margin-top: @font-size-h1;
  margin-bottom:0;
  text-transform: uppercase;
  border-bottom: none;
  font-weight: bold;
  h1, h2, h3 {
    margin-top: 0;
  }
  h2{
    font-size: 35px;
  }
}

.article {
  padding-bottom: 110px;

  .article-title {
    &:extend(.page-title);
  }

  .article-byline {
    position: relative;
    text-align: center;
    margin-bottom: @font-size-h1;
  }

  .matrix-text{
    margin-bottom: 20px;
  }

  .matrix-heading {
    width: 100%;
    float: left;
      .box{
        margin-bottom: 0.5em;
        padding:10px;
        float: left;
        margin-top: 0;
        font-size: 20px;
        font-weight: bold;
        max-width: 320px;
      }
  }
}

.meta-byline {
  margin-bottom: @padding-base-vertical;
}

.article-image {
  margin-bottom: @line-height-computed;

  .caption {
    .clearfix();
    padding: 9px;
  }
}

[class*="col-"].align-left {
  padding-left: 0;
  padding-right: 15px;

  @media (max-width: @screen-md) {
    padding-right: 0;
  }
}

[class*="col-"].align-right {
  padding-right: 0;
  padding-left: 15px;
  @media (max-width: @screen-md) {
    padding-left: 0;
  }
}

[class*="col-"].align-center {
  text-align: center;
  .caption {
    text-align: left;
  }
  .thumbnail {
    display: inline-block;
  }
}

.article-image.align-full {

}

.article-image.align-left, .article-image.align-right {

  @media (min-width: @screen-xs) {
    max-width: 50%;
  }

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

}

.article-body {
  .clearfix();
}


// Media extends
.media {
  border: 1px solid @gray-light;
  padding: @panel-body-padding;
  border-radius: @border-radius-small;

  .media-right,
  .media > .pull-right {
    padding-left: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-left,
  .media > .pull-left {
    padding-right: @panel-body-padding;
    padding-bottom: @panel-body-padding;
  }

  .media-date-byline {
    border-top: 1px solid @gray-light;
    border-bottom: 1px solid @gray-light;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: @padding-base-vertical;
  }

  .media-summary {
    p:last-child {
      margin-bottom: @padding-base-vertical;
    }
  }

  &.media-responsive {
    .media-right, .media-left {

      @media (max-width: @screen-xs) {
        display: block;
        float: none !important;
        padding-right: 0;
        padding-left: 0;

        .media-object {
          width: 100%;
        }
      }

      @media (min-width: @screen-xs + 1) {
        .media-object {
          width: 200px;
        }
      }

      @media (min-width: @screen-md) {
        .media-object {
          width: 160px;
        }
      }

    }
  }

  &-galleries {
    padding: 4px;
    background-color: #fff;
    .media-summary {
      padding: @padding-small-vertical;
    }
  }
  &-exhibition{
    padding: 0;
    border:none;
    position: relative;
      header{
        width:40%;
        float: left;
          .media-heading{
            font-size: 20px;
            font-weight: bold;
          }
          .subheader{
            font-size: 18px;
            font-weight: bold;
          }
      }
      .media-image{
        position: absolute;
        z-index: -1;
        .media-object{
          width:100%;
        }
      }
      .media-body{
        padding-top: 55%;
        display: block;
        width: 100%;
      }
      .media-summary {
        background-color: #eaeaef;
        width: 55%;
        padding: 15px;
        margin-top: -55px;
      }
      &.left{
         header{
            float: right;
          }
          .media-summary{
            float: left;
            margin-left: 15px;
          }
        }
      &.right{
        header{
          float: left;
        }
        .media-summary{
          float: right;
          margin-right: 15px;
        }
      }
  }
  &-profile{
    padding:5px;
    border:none;
    .media-heading{
      margin-bottom: 0;
      font-size: 18px;
    }
    .media-summary {
      padding: 0;
    }
    .media-object{
      width:100%;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
    }
  }
}

.profile-image{
  margin-right: 15px;
  margin-bottom: 15px;
}

.nav-stacked {
  .nav-stacked {
    > li > a {
      padding-left: 40px;
    }
  }
}

.edit-this-page {
  font-size: 14px !important;
  background: #fff;
  display: inline-block;
  padding: 4px;
  height: 22px;
  width: 22px;
  line-height: 14px;
  color: @gray;

  &:hover, &.active, &:focus {
    background: @gray;
    color: #fff;
  }
}
#footer{
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: @white;
  z-index: 999;
}
#footerNav{
  padding: 0;
  box-shadow:0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.site-footer {
  color: @text-color;
  padding: 25px 15px 13px;
  font-size: 13px;
    a{
      color:@brand-primary;
      &:hover, &:focus{
        color:#926b34;
      }
    }
}

.navbar-brand{
  height: 60px;
  padding:0;
  float: none;
    img{
      float: left;
      margin-right: 0;
      width:98px;
       @media(min-width: @screen-sm){
          margin-right: 30px;
          width:auto;
          height: 99px;
       }
    }
}
.site-title{
  text-transform: uppercase;
  font-size: 16px;
  color: #555;
  float: left;
  text-align: center;
  margin-top: 12px;
  width: 45%;
  visibility: hidden;
  height: 0;
  margin: 0;
  span{
    color:@brand-primary;
  }
  // @media(min-width: @screen-xs){
  //    margin-top: 10px;
  //    float: none;
  //    font-size: 16px;
  // }
  @media(min-width: @screen-sm){
    font-size: 20px;
    text-align: left;
    margin: 25px 0;
    visibility: visible;
    height: auto;
  }
  @media(min-width: @screen-md){
    font-size: 24px;
    margin: 35px 0;
    width: auto;
  }
  @media(min-width: @screen-lg){
    font-size: 30px;
    margin: 31px 0;
  }
}
.navbar{

  @media(min-width:@screen-xs){
    min-height: 60px;
  }
  @media(min-width:@screen-sm){
    min-height: 99px;
  }
}
.navbar-toggle{
  border-radius: 0;
  border: none;
  background-color: @brand-primary;
  margin:0;
  height: 100%;
  padding: 24px 15px;
  @media(min-width:@screen-xs){
    padding: 23px 18px;
  }
    @media(min-width:@screen-sm){
      padding: 38px 25px;
    } 

    .icon-bar{
      width: 30px;
      height: 3px;

        @media(min-width:@screen-sm){
          width: 40px;
          height: 5px;
        }
    }
}

.page-slider{
  border-top: 1px solid #d2d2d2;
  padding: 10px 0;
  width: 100%;
  bottom: 0;
  background: @white;
  z-index: 99;
  position: fixed;

  // @media(min-width:@screen-md){
  //   padding: 15px 0;
  // }
  span{
    font-weight: bold;
    text-transform: uppercase;
  }
  .nav{
    text-align: center;
    position: relative;
    margin: auto;
    width:80%;
    @media(min-width:@screen-sm){
      margin-top: 0;
      width:100%;
    }
    li{
      display: inline-block;
      width: 10px;
      margin: 0 2px;
      @media(min-width: @screen-xs){
        width:14px;
      }

        a{
          border-bottom:3px solid #d2d2d2;
          padding:7px;

            &:hover, &:focus{
              border-bottom:3px solid #000;
              background-color: transparent;
            }
        }

        &.active a{
          border-bottom:3px solid #000;
        }
        &.arrow{
          position: absolute;
          bottom: 8px;
          width: 40px;
            @media(min-width:@screen-md){
              bottom: -15px;
            }
            a{
              border-bottom: none;
              font-size: 30px;
            }
            &.right{
              right:-40px;
              @media(min-width:@screen-md){
                right: -15px;
              }
            }

            &.left{
              left:-40px;
              @media(min-width:@screen-md){
                left: -15px;
              }
            }
        }
    }
  }
}

.footer-info-btn{
    color:@brand-primary;
    background: transparent;
    border: none;
    position: absolute;
    left: 2%;
    // bottom: 50px;
    width: auto;
      @media(min-width:@screen-sm){
        bottom: 25px;
      }

      @media(min-width:@screen-md){
        bottom: 5px;
      }

      &.info .fa{
        font-size: 25px;
      }

      &.closer{
        bottom: 94%;
        position: absolute;
          .fa{
            color:@brand-primary;
            background: @white;
            border-radius: 100%;
            font-size: 35px;
            line-height: 30px;
          }
          @media(min-width: @screen-sm){
            bottom: 90%;
          }
          @media(min-width: @screen-lg){
            bottom: 86%;
          }
      } 
}

.nav-bg{
  position: absolute;
  left: 0;
}

// Nav bar dropdown

.navbar-nav > li.dropdown {
  > a {
    padding-right: 30px;
    &:after {
      .fa-icon();
      content: @fa-var-chevron-down;
      position: absolute;
      top: ~"calc(50% - 6px)";
      right: 9px;
      font-size: 0.75em;
    }
  }
}

// Nav form

.navbar-form {
  .form-control {
    background: transparent;
    border: 0;
    border-bottom: 1px solid @gray-light;
    border-radius: 0;
    color: @gray-lighter;
    box-shadow: none;
  }
}

// share

.article-sharing {
  min-height: 22px;
  margin-left: -5px;
}

.share-btn {
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
  color: @text-color;
  font-size: 14px;
}

// List group extends
.list-group-item-meta {
  font-size: @font-size-small;
}

/* notifications */
#notifications {
  z-index: 101;
}

body.ltr #notifications {
  left: 0;
}

body.rtl #notifications {
  right: 0;
}

#notifications.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
}

#notifications {
  text-align: center;

  .notification {
    .alert;
  }
  .notification[class*="message"] {
  }
  .notification[class*="notice"] {
    .alert-info;
  }
  .notification[class*="error"] {
    .alert-danger;
  }

  .notification {
    margin-bottom: 0;
    border-radius: 0;

    a {
      color: inherit;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .notification + .notification {
    border-top: 0;
  }
}

// Dropdown sub-menu

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-submenu > a:after {
  .fa-icon();
  content: @fa-var-chevron-right;
  position: relative;
  top: ~"calc(50% - 6px)";
  right: -9px;
  font-size: 0.75em;
}

.dropdown-submenu:hover > a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}

h1.gtmwarning {
  text-align: center;
  background: red;
}

/* Gallery */

.grid-sizer, .grid-item {
  width: 33%;
  margin-bottom: 5px;

}

.gallery-image {
  padding: 5px;
  width: 33%;
  float: left;
  position: relative;
}

.gallery-image-link {
  display: block;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;

  .img-responsive {
    position: absolute;
    .square(100%);
  }
  .caption {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    padding: 5px;
    color: #fff;
  }
}

.grid-item-width2 {
  width: percentage(8/12);
}

.grid-item-height2 {
  img {
    width: 100%;
    overflow: hidden;
  }
  .lightbox {
    padding-bottom: 100%;
  }
}

.gallery-link {
  position: relative;

  &:hover .caption {
    display: block;
  }

  .caption {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
  }

}

.mfp-description {
  color: #ccc;
  margin-top: 10px;
}

a.thumbnail:hover, a.thumbnail:active {
  text-decoration: none;
}


// Form extends

.field.form-group {
  position: relative;
}

.sprout-email-button {
  padding: 0 !important;
  right: 6px !important;
  bottom: 11px !important;
  margin: 0 !important;
  line-height: 1 !important;
}

.field .instructions {
  color: lighten(@text-color, 25%);
  font-size: @font-size-small;
  margin-bottom: 5px;
  *:last-child {
    margin-bottom: 0;
  }
}

.control-label, label {
  + .instructions {
      margin-top: -5px;
  }
}

label.required:after {
  content: ' *';
  color: @brand-danger;
}

.home-video {
  position: relative;
  overflow: hidden;
  


  // .intro {
  //   left:40px;
  // }

    @media(min-width: @screen-sm){
        .horizontal-align-center {
        left:65%;
        }
   }


  .banner-link .fa {
    margin:0;
  }
  .overlay{
    background-color: rgba(0, 0, 0, 0.45);
    position: absolute;
    width: 100%;
    height:100%;
     
  }
  video{
    width: 255%;
    margin-left: -75%;

      @media(min-width: @screen-sm){
        width: 200%;
        margin-left: -65%;
      }
      @media(min-width: @screen-md){
        width: 125%;
        margin-top: -5%;
        margin-left: -24%;
      } 
      @media(min-width: @screen-lg){
        width:120%;
        margin-top: -8%;
        margin-left: -19%;
      }
  }
}
#primaryNav{
  background-position: -75% 0;
  @media(min-width: @screen-xs){
    background-position: 0;
  }
  @media(min-width: @screen-sm){
    background-position: -150%;
  }
  @media(min-width: @screen-md){
    background-position: 21%;
  }
  .navbar-nav{
    margin: 0;
    position: absolute;
    right: 0;
    top:10%;
    width: 100%;
      li{
          text-align: center;
          .make-xs-column(4);
          height: 90px;
          @media(min-width:@screen-sm){
            height: 120px;
          }
          @media(min-width:@screen-md){
            height: 100px;
          }


          span{
              width: 100%;
              float: left;
              font-size: 30px;
              font-weight: bold;
              position: relative;
              @media(min-width:@screen-sm){
                font-size: 50px;
              }
              @media(min-width:@screen-md){
                font-size: 35px;
              }
          }
          a{
            text-transform: uppercase;
            font-size: 14px;
            line-height: 1.1;
            font-weight: bold;
            padding:0;

              &:hover, &:focus{
                color:@white;
              } 

              @media(min-width:@screen-sm){
                color:@white;
                font-size: 20px;
              }
              @media(min-width:@screen-md){
                color:@gray;
                font-size: 15px;
              }
          }
          &.active a{
            color:@white;
          }
        // &:first-child{
        //   display: none;
        // }  
      }
      @media(min-width: @screen-xs){
        right: 0;
        top: 0;
        height: 100%;
        padding-top: 5%;
        width:45%;
      }
      @media(min-width: @screen-sm){
        right: 0;
        top: 0;
        height: 100%;
        padding-top: 30%;
        width:100%;
        background-color: rgba(0, 0, 0, 0.6);
      }
      @media(min-width: @screen-md){
        right: 0;
        top:10%;
        background-color: transparent;
        height: auto;
        padding-top: 0;
        width:39%;
      }
      @media(min-width: @screen-lg){
        right: 5%;
        width:40%;
      }
  }
}
.navmenu{
  width:100%;
}
.menu-close{
  position: absolute;
  right:20px;
  font-size: 30px;
  z-index: 9;
}
.matrix-downloads .list-group-item{
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
  padding:1.5% 10%;
  width:450px;
  display: inline-block;
  border:3px solid #000;
    .list-group-item-heading{
      font-size: 20px;
      float: left;
      width: 100%;
      text-transform: uppercase;
        .fa{
          float: right;
        }
    }
}
.page-logo{
  margin-top:10px;
  width:75%;
  // float: left;
  // margin-right: 25px;
  //   img{
  //       height: 250px;
  //   }
}

// .theyearahead{
//   .banner {
//     .banner-image img{
//       height: ~'calc(100vh - 180px)';
//     }
//     .banner-body {
//       width: 70%;
//       .banner-title{
//         margin-top: 0;
//         margin-bottom: 30px;
//       }
//     }  
//   }

//   .main-panel-title{
//     position: absolute;
//     color: @white;
//     width:100%;
//     text-align: center;
//     font-size: 50px;
//     top:0;
//     z-index: 9;
//   }
// }
small.attribution{
    position: absolute;
    top: 2px;
    right:2px;;
    color: #fff;
    opacity:0.5;
}
.large-title{
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  @media(min-width: @screen-sm){
    font-size: 40px;
    font-weight: bold;
    line-height: 40px;
  }
}



// LEGACY BANNER
// JUST FOR HOME PAGE UNTIL I CAN FIX

.legacy-banner{
  position: relative;
  height: calc(~'100vh - 160px');
  overflow: hidden;

  @media(min-width: @screen-sm){
    height: calc(~'100vh - 180px');
  }

  .banner-image {
    position: relative;
    img{
      @media(min-width: @screen-md){
        height: calc(~'100vh - 180px');
      }
      @media(min-width: @screen-lg){
        height: auto;
      }
    }
  } 

  .banner-body{
    color: @text-color;
    top:50%;
    font-weight: 300;
    width: 90%;

      @media(min-width: 375px){
        top:42%;
      }

      @media(min-width: @screen-sm){
        top:40%;
      }

      @media(min-width: @screen-md){
        color: @white;
        width: 35%;
        top:50%;
      }

      header{
        width: 50%;
        text-transform: uppercase;

          @media(min-width: @screen-sm){
            margin-bottom: 120px;
          }
          @media(min-width: @screen-md){
            width: 100%;
            margin-bottom: 0;
          }
      }
  }

  .banner-title{
    font-size: 20px;
    font-weight: bold;
    color: @white;
    text-shadow: 1px 1px 5px #000;
    line-height: 1;
    border: 4px solid @white;
    padding: 10px;
    margin-bottom: 5px;  
    margin-top: 5px; 
    float: left;
    width: 145px;

      @media (min-width: 375px) {
        font-size: 23px;
        padding: 15px;
         width: 170px;
      }

      @media (min-width: @screen-sm) {
          font-size: 35px;
          line-height: 0.8;
          margin-bottom: 0;
          margin-top: 24px;
          width: 240px;
      }
  }

  .subtitle{
    color:@white;
    font-size: 15px;
    padding-bottom: 12px;
    margin-bottom: 30px;
    margin-top: 10px;
    clear:both;
    float: left;
    position: relative;
    font-weight: bold;
      &:after{
        content:"";
        position: absolute;
        border-bottom: 3px solid #fff;
        bottom:0;
        left: 0;
        width:90px;
      }
      @media(min-width: 375px){
        font-size: 18px;
        margin-top: 15px;
      } 
      @media(min-width: @screen-sm){
        font-size: 25px;
        margin-bottom: 20px;
        margin-top:24px;
      }
  }
}